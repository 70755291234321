@import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Rubik:wght@500&display=swap');

.fraCode
{
    font-family: 'Audiowide', cursive;
    font-size: 20px;;
}
.fraName
{
    font-family: 'Rubik', sans-serif;
    font-size: 17px;
}
