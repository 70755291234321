.container
{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
}
.form
{
    width: 400px;
    padding: 25px;
    border-radius: 12px;
    border: 1px solid #f1f1f1;
    height: 100%;
    box-shadow: 0 0 14px 0px #e9e9e9;
    padding:25px;
    border-radius: 22px;
    height: 100%;
    background-color: rgb(255, 255, 255);
}

.label-custom {
    /* Your custom label styles here */
    color: #333;
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 5px;
    /* Add any other custom styles as needed */
  }
  .formControl
  {
    border-radius: 10px;
    padding:10px;
  }
  .button {
    border-radius: 22px;
    background-color: rgb(10, 76, 207); /* Button background color */
    color: white;
    padding: 5px 10px;
    text-decoration: none;
    padding: 10px 20px 10px 20px;
    text-transform: uppercase;
    font-size: 13.5px;
    border:none;
  }
  .button:hover {
    background-color: rgb(37, 85, 242); /* Button background color */
  }

  .notvalid
  {
    border-radius: 8px;
    padding:15px;
    background-color: rgb(238, 169, 169);
    color:rgb(95, 0, 0);
    font-size: 14px;
  }